import React from "react"
import ReactBreakpoints from 'react-breakpoints'

import Chatbot from "../components/chatbot"
import ClientList from "../components/clientlist"

import "./masthead.scss"

const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}

const Masthead = () => (
  <section className="masthead">
    <div className="masthead__container">
      <div className="row d-flex align-items-center masthead__content">
      
        <div className="col-lg-8 col-md-12 h-100">
          <div className="d-flex align-items-center h-100">
            <div className="masthead__intro">
              <h1 className="masthead__title"><span className="masthead--brushstroke">Hi, I'm Colin.</span> Nice to meet you.</h1>
              <p className="masthead__subtitle">I’m an Ottawa-area senior front-end web developer. For over 8 years, I’ve crafted engaging designs, developed modern web applications, mapped user experiences and built rock-solid teams.</p>
              <a href="#showcase" className="btn btn--primary mr-3">View Work</a>
              <a href="#contact" className="btn btn--primary-outlined mr-3">Get in Touch</a>
            </div>
          </div>

          <ClientList />

        </div>

        <div className="col-lg-4 h-100 chatbot__container">
          <div className="masthead__chatbot">
            <ReactBreakpoints
              breakpoints={breakpoints}
              debounceResize={true}
            >
              <Chatbot />
            </ReactBreakpoints>
          </div>
        </div>

      </div>
    </div>
  </section>
)

export default Masthead
