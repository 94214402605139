import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Masthead from "../components/masthead"
import About from "../components/about"
import Showcase from "../components/showcase"
import Projects from "../components/projects"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<Masthead />
		<About />
		<Projects />
		<Contact />
		<Footer />
	</Layout>
)

export default IndexPage
