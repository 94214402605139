import React from "react"

import "./footer.scss"

const Footer = () => (
	<footer className="footer">
		© {new Date().getFullYear()}
	</footer>
)

export default Footer
