import React from "react"

import "./about.scss"

const About = () => (
	<section id="about" className="section about">
		<div className="container">
			<div className="col-lg-8 offset-lg-2 col-md-12">
				<div className="row d-flex align-items-top masthead__content">
				
					<div className="col-lg-4 col-md-12 h-100 section-yellow">
						<h2 className="title-yellow">About me</h2>
						<p className="subtitle">Designer, Developer, UX and more.</p>
					</div>

					<div className="col-lg-8 col-md-12 h-100 about__description">
						<p>I am an experienced full-stack web developer from Ottawa who specializes in user-experience design, web design and web development.</p>
						<p>For over 8 years, I've worked with private and public sector clients to build services and applications for the web that solve real problems.</p>
						<p>If you have a project in mind, don’t hesitate to <a href="#contact">get in touch.</a></p>
					</div>
				</div>
			</div>
		</div>
	</section>
)

export default About
