import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ProjectItem from "../components/project-item"

import "./projects.scss"

const Projects = () => (

	<StaticQuery
		query={graphql`
			query {
				allMarkdownRemark(filter: {
					frontmatter: {
						category: {
							eq: "project"}
						}
					}) {
					edges {
						node {
							frontmatter {
								category
								title
								description
								link
							}
							html
						}
					}
				}
			}
		`}
		render={data => (

			<section className="section projects">
				<div className="container">
					<div className="col-lg-8 offset-lg-2 col-md-12">
						
						<div className="row">
							
							<div className="col-lg-12">
								<h2 className="title-yellow">Personal Projects</h2>
							</div>

						</div>

						{data.allMarkdownRemark.edges.map(function(data, index) {
							return(
								<ProjectItem
									key={index}
									title={data.node.frontmatter.title}
									description={data.node.frontmatter.description}
									url={data.node.frontmatter.link}
								/>
							);
						})}

					</div>
				</div>
			</section>

		)}
	/>
)

export default Projects