import React from "react"

import "./contact.scss"

const Contact = () => (
	<section id="contact" className="section contact">
		<div className="container">
			<div className="col-lg-8 offset-lg-2 col-md-12">
				<div className="row">
		
					<div className="col-lg-12 col-md-12 text-center">
						<h2 className="title-yellow">Get in touch</h2>
					</div>

					<form action="https://formspree.io/colin@colinsmith.dev" method="POST" className="contact__form">
						<div className="col-lg-12 col-md-12 section-title-white">
							<div className="row">

								<div className="col-lg-6 input-lg mt-3">
									<label className="contact__input-label" htmlFor="name">Name</label>
									<input className="form-control contact__input" type="text" name="name" id="name" placeholder="Name" />
								</div>

								<div className="col-lg-6 input-lg mt-3">
									<label className="contact__input-label" htmlFor="email">Email</label>
									<input className="form-control contact__input" type="text" name="email" id="email" placeholder="Email" />
								</div>

								<div className="col-lg-12 input-lg mt-3">
									<label className="contact__input-label" htmlFor="subject">Subject</label>
									<input className="form-control contact__input" type="text" name="subject" id="subject" placeholder="Subject" />
								</div>

								<div className="col-lg-12 input-lg mt-3">
									<label className="contact__input-label" htmlFor="message">Message</label>
									<textarea className="form-control contact__textarea" name="message" id="message" rows="10"></textarea>
								</div>
							</div>

							<div className="row mt-3">
								<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 input-lg">
									<button className="btn btn-primary btn--primary contact__send" type="submit" value="Send">Send</button>
								</div>
								<div className="col-xs-12 col-sm-6 col-md-8 col-lg-8 contact__email-client">
									<div className="w-100">Or use your <a href="mailto:colin@colinsmith.dev">own email client instead!</a></div>
								</div>
							</div>
						</div>
					</form>

				</div>
			</div>
		</div>
	</section>
)

export default Contact
