import React from "react"
// import Modal from "../components/modal"
import Modal from 'react-responsive-modal';

import "./card.scss"
import "./modal.scss"

class Card extends React.Component {

	state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

	render() {
		const { open } = this.state;

		return (
			<div className="card masonry__item">

				<div className="card__thumbnail">
					<img className="card__img" src={this.props.thumbnail} alt={this.props.title} />
				</div>

				<button type="button" className="card__toggle" onClick={this.onOpenModal}>
					<ul className="card__info">
						<li className="card__title">{this.props.title}</li>
						<li className="card__description">{this.props.subtitle}</li>
					</ul>
				</button>
				
				<Modal open={open} onClose={this.onCloseModal} classNames={{modal: 'modal__container', overlay: 'modal__overlay', closeButton: 'modal__close-btn'}} focusTrapped>
					<div className="row">
						
						<div className="col-lg-9 col-md-12 modal__content pl-0 pr-0" dangerouslySetInnerHTML={{ __html: this.props.modalContent }}>
							
						</div>
						
						<div className="col-lg-3 col-md-12 modal__sidebar pl-0 pr-0">
							<div className="modal__metadata">
								
								<div className="modal--padding">
									<h3 className="modal__title">{this.props.title}</h3>
									<p className="modal__description">{this.props.subtitle}</p>
								</div>

								<ul className="modal__tags modal--padding">
									{this.props.tags.map(function(name, index) {
										return <li className="modal__tag-item" key={ index }>{name}</li>;
									})}
								</ul>

							</div>
						</div>

					</div>
				</Modal>

			</div>
		);
	}
}

export default Card