import React from "react"

import "./project-item.scss"

const ProjectItem = (props) => (
	<div className="row d-flex align-items-top project__item">
		
		<div className="col-lg-9 col-md-8 col-sm-12">
			<div className="row">
				<div className="col-md-4 col-sm-12">
					<h3 className="project__title">{props.title}</h3>
				</div>

				<div className="col-md-8 col-sm-12">
					<p>{props.description}</p>
				</div>
			</div>
		</div>

		<div className="col-lg-3 col-md-4 col-sm-12 project__link">
			<a href={props.url} className="btn btn-sm btn--black-outlined project__button">View on Github</a>
		</div>
	</div>
)

export default ProjectItem