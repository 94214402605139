import React from "react"

import bf from "../images/logos/bf.png"
import cf from "../images/logos/cf.png"
import foko from "../images/logos/foko.png"
import ottawa from "../images/logos/ottawa.png"
import tartan from "../images/logos/tartan.png"

import "./clientlist.scss"

const ClientList = () => (
  <ul className="clientlist">
    <li className="clientlist__item">
      <img src={bf} alt="Bluesfest" />
    </li>
    <li className="clientlist__item">
      <img src={cf} alt="Cityfolk" />
    </li>
    <li className="clientlist__item">
      <img src={foko} alt="Foko" />
    </li>
    <li className="clientlist__item">
      <img src={ottawa} alt="City of Ottawa" />
    </li>
    <li className="clientlist__item">
      <img src={tartan} alt="Tartan Homes" />
    </li>
  </ul>
)

export default ClientList

