import React from "react"
import ChatBot from 'react-simple-chatbot'

import "./chatbot.scss"

const desktopProps = {
	hideBotAvatar: true,
	hideUserAvatar: true,
	width: '100%',
	headerTitle: 'Ask me anything!',
	placeholder: 'Ask me anything...',
	bubbleStyle: {
		position: 'relative',
		fontFamily: 'Lato, Helvetica, Arial, sans-serif',
		background: '#FFE98F',
		color: '#00102A',
		fontSize: '.5rem .75rem',
		margin: '0 0 1rem 0',
		padding: '.75rem',
		borderRadius: '4px',
		maxWidth: '75%'
	}
};

const steps = [
  {
    id: '1',
    message: 'Welcome!',
    trigger: '2'
  },
  {
    id: '2',
    message: 'First lets get introductions out of the way. What is your name?',
    trigger: '3'
  },
  {
    id: '3',
    user: true,
    trigger: '4'
  },
  {
    id: '4',
    message: 'It\'s wonderful to meet you {previousValue}! Now it\'s my turn to answer some questions! Why not ask me about...',
    trigger: '5'
  },
  {
    id: '5',
    options: [
      { value: 1, label: 'Experience', trigger: '6' },
      { value: 2, label: 'Hobbies', trigger: '13' },
      { value: 3, label: 'Projects', trigger: '16' }
    ]
  },
  {
    id: '6',
    message: 'I\'ve been developing professionally for over 8 years!',
    trigger: '7'
  },
  {
    id: '7',
    message: 'In that time, I\'ve had the pleasure of developing websites for political campaigns, national campaigns, charities, agencies and more.',
    trigger: '8'
  },
  {
    id: '8',
    options: [
      { value: 1, label: 'Client work', trigger: '9' },
      { value: 2, label: 'Personal projects', trigger: '11' },
      { value: 3, label: 'Other', trigger: '5' }
    ]
  },
  {
    id: '9',
    message: 'I have been directly involved with local events like Ottawa Bluesfest, Cityfolk Festival, non-profits like The Snowsuit Fund, Fairy Godmother Ottawa, Rideau Canal Festival and campaigns put on by Ottawa Tourism among others.',
    trigger: '10'
  },
  {
    id: '10',
    message: 'I take pride in knowing that my work has been seen by millions of people and continues to be recognized locally and internationally.',
    trigger: '8'
  },
  {
    id: '11',
    message: 'Playing around with new technologies, ideas and approaches is what I love most! My interests lately are with experimenting with new approaches to interfaces (take this chat interface, for example), javascript and it\'s rich ecosystem.',
    trigger: '12'
  },
  {
    id: '12',
    message: 'Curious what I have on the go right now? A recipe database powered by React and Mongo, 3D chat clients and more!',
    trigger: '8'
  },

  {
    id: '13',
    message: 'I have a lot of different hobbies!',
    trigger: '14'
  },
  {
    id: '14',
    message: 'I spend a lot of time skimming Science Daily, Ad Week, Hacker News, Designer News, Architectural Digest and Reddit.',
    trigger: '15'
  },
  {
    id: '15',
    message: 'On the fun side of things, I love to play video games (all Playstation, Xbox and PC!) with friends, practising 3D sculpting & modelling (Z-brush), getting out in nature or hitting up local events and restaurants.',
    trigger: '5'
  },

  {
    id: '16',
    message: 'Too many projects, not enough time!',
    trigger: '17'
  },
  {
    id: '17',
    message: 'Right now, I\'m currently working on an online recipe web scraper that consolidates favourite recipies in one place!',
    trigger: '5'
  },
];

class Chatbot extends React.Component {

	render() {
		return (
			<ChatBot className="chatbot__desktop" steps={steps} {...desktopProps} />
		);
	}

}

export default Chatbot
